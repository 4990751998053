<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card max-width="600px" class="mx-auto mb-6 px-2 py-1" elevation="6">
      <v-form ref="searchForm" v-if="!isPersonAlreadyLogin">
        <v-expansion-panels flat v-model="personSearchPanelIndex">
          <v-expansion-panel>
            <v-expansion-panel-header class="mt-4 mb-6 text-h6">
              {{ searchCardTitle }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select
                v-if="isNodeRequired"
                outlined
                dense
                :label="label.nodeTitle"
                :items="nodeInfoList"
                v-model="personSearch.nodeGuid"
              ></v-select>
              <v-text-field
                outlined
                dense
                v-model="personSearch.loginKey"
                :loading="isSearchPersonLoading"
                :label="loginKeyLabel"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                type="password"
                v-model="personSearch.password"
                :loading="isSearchPersonLoading"
                label="密码"
              ></v-text-field>
              <div class="d-flex justify-space-between">
                <v-btn
                  class="mr-4"
                  text
                  color="primary"
                  depressed
                  @click="switchToCreateMode"
                >
                  <v-icon class="mr-2">mdi-account-plus</v-icon>
                  注册
                </v-btn>
                <v-btn color="primary" depressed @click="searchPerson">
                  <v-icon class="mr-2">mdi-login-variant</v-icon>
                  登录
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider></v-divider>
      </v-form>
      <v-form ref="infoForm" v-if="isCreateNewPerson || !!searchedPersonGuid">
        <v-card-title class="mt-4 mb-6 text-h6">
          {{ infoCardTitle }}
        </v-card-title>
        <v-card-text v-if="isPersonInfoLoaded" class="px-8">
          <PersonInfoEditor
            :node-guids="[nodeGuid]"
            :region-guid="regionGuid"
            v-model="personInfo"
            :disabled-fields="disabledPersonInfos"
            :is-creating-new="isCreateNewPerson"
            :no-update-fields="personNoUpdateFields"
            @update:no-update-fields="personNoUpdateFields = $event"
            :field-config-list="fieldConfigList"
            :node-list="nodeInfoList"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            class="px-8"
            @click="submitPersonInfo"
          >
            确认
            <v-icon dense class="ml-2">mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="app-card-text">
          个人信息经过保密处理，请放心填写
        </v-card-text>
      </v-form>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
    <AppMessageBox v-model="personNotFoundMsg" title="未找到该用户" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import PersonInfoEditor from "@/components/PersonInfoEditor";
import { mapGetters } from "vuex";
import { searchPersonInfo } from "@/api/sca";
import { fetchPrimaryPersonInput } from "@/api/manageNode";
import { personInputTypes } from "@/utils/constants/user";
import { simpleDeepCopy } from "@/utils/objectUtils";
import {
  createNewTestPerson,
  saveByCreatingNewEditedPerson
} from "@/api/person";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { fetchPersonInfoInternal } from "@/api/sca";
import { fetchVisibleIdentityTypeList } from "@/api/userIdentity";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    PersonInfoEditor
  },

  data() {
    return {
      // loading
      isLoading: false,
      isSearchPersonLoading: false,
      // meta data
      isNodeRequired: false,
      label: {
        userDispName: "",
        nodeTitle: ""
      },
      fieldConfigList: [],
      nodeInfoList: [],
      // message box
      personNotFoundMsg: "",
      errorMsg: "",
      // search
      personSearch: {
        nodeGuid: "",
        loginKey: "",
        password: ""
      },
      emptyPersonSearch: {
        nodeGuid: "",
        loginKey: "",
        password: ""
      },
      nodeIdentityTypes: [],
      searchedPersonGuid: "",
      isCreateNewPerson: false,
      personSearchPanelIndex: 0,
      // person info
      personInfo: {
        guid: "",
        userGuid: "",
        personUniGuid: "",
        loginIdentity: {
          guid: "",
          loginKey: "",
          loginKeyType: {
            typeText: "",
            typeValue: ""
          }
        },
        hasPassword: false,
        password: "",
        name: "",
        gender: "",
        age: "",
        eduLevel: "",
        marriage: "",
        job: "",
        nodeGuid: "",
        nodeName: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: ""
      },
      emptyPersonInfo: {
        guid: "",
        userGuid: "",
        personUniGuid: "",
        loginIdentity: {
          guid: "",
          loginKey: "",
          loginKeyType: {
            typeText: "",
            typeValue: ""
          }
        },
        hasPassword: false,
        password: "",
        name: "",
        gender: "",
        age: "",
        eduLevel: "",
        marriage: "",
        job: "",
        nodeGuid: "",
        nodeName: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: ""
      },
      disabledPersonInfos: [],
      personNoUpdateFields: [],
      // person selection/confirm
      isPersonInfoLoaded: false
    };
  },

  computed: {
    ...mapGetters({
      regionGuid: "sca/regionGuid",
      nodeGuid: "sca/nodeGuid",
      nodeName: "sca/nodeName",
      // 已登录的 personGuid
      personGuid: "sca/personGuid"
    }),
    isPersonAlreadyLogin() {
      return this.personGuid;
    },
    searchCardTitle() {
      return "测量用户登录";
    },
    infoCardTitle() {
      return this.searchedPersonGuid ? "确认个人信息" : "注册新用户";
    },
    loginKeyLabel() {
      if (this.nodeIdentityTypes && this.nodeIdentityTypes.length) {
        return this.nodeIdentityTypes.map(idt => idt.text).join("/");
      }
      return "";
    }
  },

  watch: {
    isCreateNewPerson(newVal) {
      // 切换成注册模式时，清空
      if (newVal) {
        this.resetPersonInfo();
        this.resetPersonInfoSearch();
      }
    }
  },

  methods: {
    async getPrimaryPersonInput() {
      try {
        this.isLoading = true;
        let primaryPersonInput = await fetchPrimaryPersonInput(this.nodeGuid);
        // 默认为搜索模式，入宫时create，则切换到注册模式
        if (primaryPersonInput === personInputTypes.create.value) {
          this.switchToCreateMode();
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getLoggedInPersonInfo() {
      if (this.isPersonAlreadyLogin) {
        try {
          this.isLoading = true;
          let personInfoFound = await fetchPersonInfoInternal(this.personGuid);
          Object.assign(this.personInfo, simpleDeepCopy(personInfoFound));
          this.switchToLoggedInMode();
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    switchToCreateMode() {
      this.isCreateNewPerson = true;
      this.personSearchPanelIndex = null;
      this.disabledPersonInfos = [];
    },
    switchToSearchMode() {
      this.isCreateNewPerson = false;
      this.personSearchPanelIndex = 0;
      this.disabledPersonInfos = ["nodeGuid"];
    },
    switchToLoggedInMode() {
      this.isCreateNewPerson = false;
      this.personSearchPanelIndex = null;
      this.disabledPersonInfos = ["nodeGuid"];
    },
    resetPersonInfoSearch() {
      Object.assign(this.personSearch, simpleDeepCopy(this.emptyPersonSearch));
    },
    resetPersonInfo() {
      this.searchedPersonGuid = "";
      Object.assign(this.personInfo, simpleDeepCopy(this.emptyPersonInfo));
      this.$refs.infoForm && this.$refs.infoForm.resetValidation();
    },
    async searchPerson() {
      try {
        this.isSearchPersonLoading = true;
        this.switchToSearchMode();
        let personSearched = await searchPersonInfo(
          this.personSearch.loginKey,
          this.personSearch.password
        );
        if (personSearched) {
          this.searchedPersonGuid = personSearched.guid;
          // 使用哪个Node测量，就疏于哪个Node
          personSearched.nodeGuid = this.nodeGuid;
          personSearched.nodeName = this.nodeName;
          Object.assign(this.personInfo, simpleDeepCopy(personSearched));
          this.personSearchPanelIndex = 1;
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isSearchPersonLoading = false;
    },
    async getNodeFieldConfig() {
      try {
        this.isLoading = true;
        this.fieldConfigList = await getUserFieldConfig([this.nodeGuid]);
        let nodeConfig = this.fieldConfigList.find(
          fc => fc.fieldName === "nodeGuid"
        );
        this.label.nodeTitle = nodeConfig ? nodeConfig.fieldAlias : "";
        this.isNodeRequired = nodeConfig ? nodeConfig.isRequired : false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getNodeIdentityTypes() {
      try {
        this.isLoading = true;
        this.nodeIdentityTypes = await fetchVisibleIdentityTypeList(
          this.regionGuid,
          null,
          [this.nodeGuid]
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    getNodeSelectList() {
      this.nodeInfoList = [
        {
          text: this.nodeName,
          value: this.nodeGuid
        }
      ];
      // 只有一个 node，自动选上
      let onlyNode = this.nodeInfoList[0];
      this.personSearch.nodeGuid = onlyNode.value;
      this.emptyPersonSearch.nodeGuid = onlyNode.value;
      this.emptyPersonInfo.nodeGuid = onlyNode.value;
    },
    async submitPersonInfo() {
      let isValid = this.$refs.infoForm.validate();
      if (isValid) {
        // 必须要添加 identity
        if (
          !this.personInfo.loginIdentity ||
          !this.personInfo.loginIdentity.loginKeyType ||
          !this.personInfo.loginIdentity.loginKeyType.typeValue
        ) {
          this.errorMsg = "未设置答题账号，请点击上方“+”添加答题账号";
          return;
        }
        if (!this.personInfo.nodeGuid) {
          this.errorMsg = "未设置测评单位";
          return;
        }
        this.isLoading = true;
        // 判断是登录还是注册用户
        let personGuid = "";
        if (this.searchedPersonGuid) {
          personGuid = await this.updateExistingPerson();
        } else {
          personGuid = await this.createNewPerson();
        }
        await this.setPersonAndMoveToNextPage(personGuid);
        this.isLoading = false;
      }
    },
    async updateExistingPerson() {
      try {
        this.isLoading = true;
        let editedNewPersonGuid = await saveByCreatingNewEditedPerson(
          this.regionGuid,
          {
            ...this.personInfo,
            guid: this.searchedPersonGuid
          },
          this.personNoUpdateFields
        );
        this.searchedPersonGuid = editedNewPersonGuid;
        return editedNewPersonGuid;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async createNewPerson() {
      try {
        let newPersonGuid = await createNewTestPerson(
          this.regionGuid,
          {
            ...this.personInfo
          },
          this.personInfo.password
        );
        return newPersonGuid;
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async setPersonAndMoveToNextPage(personGuid) {
      if (personGuid) {
        this.$store.dispatch("sca/setPersonGuid", personGuid);
        this.$router.push({ name: "scaselect" });
      }
    }
  },

  destroyed() {
    this.isPersonInfoLoaded = false;
  },

  async created() {
    // 如果已经登录过，则直接获取 personInfo
    if (this.isPersonAlreadyLogin) {
      await this.getLoggedInPersonInfo();
      this.searchedPersonGuid = this.personGuid;
    } else {
      await this.getPrimaryPersonInput();
      this.resetPersonInfo();
      await this.getNodeIdentityTypes();
    }
    await this.getNodeFieldConfig();
    // 如果只有一个 node，则可以自动选上
    this.getNodeSelectList();
    this.isPersonInfoLoaded = true;
  }
};
</script>

<style lang="scss" scoped>
.app-card-text {
  color: rgba(0, 0, 0, 0.6);
}
</style>
